import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Link } from 'components/atoms';
import WorkCard from 'components/molecules/WorkCard';
import TagsContainer from 'components/molecules/TagsContainer';

import { useAllCaseStudyWorkArray } from 'graphql/hooks';

import { htmlCheck } from 'utils/html';

const WorksFeatured = ({ data, className, tagsData }) => {
  const { css_id, caption, tags, works } = data;
  const { title: tags_title, tags: tags_list } = tags;
  const { title, description } = caption;
  const worksData = useAllCaseStudyWorkArray();

  const tagsInfo = tagsData.map(edges => edges.node);

  const tagsMap =
    tags_list &&
    tags_list.map(({ term_id }, id) => {
      const tag = tagsInfo.find(({ wordpress_id }) => wordpress_id === term_id);
      const { path, name } = tag;

      const isLink = path === null;

      const textOrLink = isLink ? (
        <div className={`${className}__tags__link-item`} key={id}>
          <Text className={cn(`${className}__tag-item`, 'body-sm_bold')}>
            {name}
          </Text>
        </div>
      ) : (
        <div className={`${className}__tags__link-item`} key={id}>
          <Link className={`${className}__link`} href={path}>
            <Text className={cn(`${className}__tag-item`, 'body-sm_bold')}>
              {name}
            </Text>
          </Link>
        </div>
      );

      return textOrLink;
    });

  const worksMap =
    works &&
    works.map(({ wordpress_id: workId }, id) => {
      const work = worksData.find(
        ({ wordpress_id: itemId }) => workId === itemId
      );

      if (!work) {
        return null;
      }

      work.acf.use_default_featured_image_on_mobile = false;

      return <WorkCard size="small" data={work} key={id} />;
    });

  const headerTitle = htmlCheck(title);
  const headerDescription = htmlCheck(description);

  return (
    <Section className={className} id={css_id || data.id}>
      <Container>
        <Row>
          <Column lg="11" col="12" className="offset-lg-1">
            {title &&
              (headerTitle ? (
                <TagsContainer
                  className={cn(
                    'tags-container_works-featured',
                    'tags-container_works-featured_title'
                  )}
                >
                  {title}
                </TagsContainer>
              ) : (
                <Text tag="h2" className={`${className}__title`}>
                  {title}
                </Text>
              ))}
          </Column>
        </Row>
        <Row className={`${className}__block`}>
          <Column
            lg="6"
            col="12"
            className={`${className}__description offset-lg-1`}
          >
            {headerDescription ? (
              <TagsContainer className={cn('tags-container_works-featured')}>
                {description}
              </TagsContainer>
            ) : (
              <Text>{description}</Text>
            )}
          </Column>
          <Column lg="3" col="12" className={`${className}__tags`}>
            <Text className={`${className}__tags__title`}>{tags_title}</Text>
            {tagsMap}
          </Column>
        </Row>
        {worksMap && (
          <Row>
            <Column lg="9" className={cn('offset-lg-1', `${className}__cards`)}>
              {worksMap}
            </Column>
          </Row>
        )}
      </Container>
    </Section>
  );
};

WorksFeatured.defaultProps = {
  className: 'works-featured',
};

WorksFeatured.propTypes = {
  data: PropTypes.object.isRequired,
  worksData: PropTypes.array.isRequired,
  tagsData: PropTypes.array,
  className: PropTypes.string,
};
export default WorksFeatured;
